@media (max-width: 767.98px) {
	header {
		border-bottom-right-radius: 14px;
	}

	.header-nav-menu:after {
		display: none;
	}

	.header-block {
		height: 46px;
	}

	.custom-mobile-burger-menu {
		background: #ffffff80 !important;
		border-radius: 4px !important;
		padding: 10px 12px !important;
	}
}

@media (991px > width) {
	.bottom-images-style {
		margin-top: 80px;
	}

	.company-sider-block {
		display: none;
	}

	.mobile-company-side-block {
		display: block !important;
	}

	.edit-info-company {
		margin-bottom: 15px !important;
	}
}

@media (1991.99px > width) {
	.ant-layout-sider-collapsed.custom-sider-style .custom-sider-footer {
		padding-bottom: 40px;
	}
}
