.candidates-table .candidates-list-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.candidates-table .candidates-list-btn span {
	color: #732135;
	font-family: manroeMedium;
	font-size: 16px;
	line-height: 1;
}

.candidates-table .candidates-list-btn:hover span {
	color: #ffffff;
}

.candidates-table .user-profession p {
	margin: 5px 0;
}

.candidates-table tbody tr td {
	font-family: manroeMedium;
	font-size: 16px;
}

.view-candidates-info .user .ant-image img,
.candidates-table .candidates-info .ant-image img {
	border-radius: 50%;
	position: relative;
}

.view-candidates-info .user .ant-image img {
	border: 3px solid #eff1f1;
	box-sizing: content-box;
}

.candidates-table .candidates-name {
	margin-left: 15px;
}

.view-candidates-info .user .company-name {
    margin-bottom: 20px;
}

.company-details-step-three.view-candidates-info .ant-layout-sider {
	padding: 0 30px 0 0 !important;
	border-right: 1px solid #aeaeae;
}

.candidates-categories {
	font-family: manroeLight;
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 7px;
	line-height: 1;
}

.view-candidates-info .company-name-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* .view-candidates-info .company-name-section .user {
    padding: 0 20px;
} */

.view-candidates-info .contact-data-label {
	text-transform: capitalize;
}

.view-candidates-info ul li {
	list-style: none;
	position: relative;
}

.view-candidates-info ul li h4 {
	font-family: manroeBold;
	font-size: 16px;
	margin: 0;
}

.view-candidates-info ul li:before {
	content: '';
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background: var(--base-bordo);
	position: absolute;
	top: 6px;
	left: -20px;
}

.view-candidates-info .skills {
	border-bottom: 1px solid #aeaeae;
}

.company-details-step-three.view-candidates-info {
    border: none !important;
}

.buy-candidates-contact-btn {
    background: #FFD43B;
    box-shadow: none;
    margin-top: 20px;
	min-height: 42px;
    height: auto;
    font-family: manroeLight;
    font-size: 16px;
    line-height: 1;
	text-wrap: balance;
}

.mobile-company-side-block .buy-candidates-contact-btn {
	margin-top: 0 !important;
}

.mobile-company-side-block .candidates-name {
	font-family: manroeBold;
    font-size: 20px;
    padding-left: 10px;
}

.mobile-company-side-block .mobile-categories-list p {
	font-family: manroeMedium;
    font-size: 16px;
}

.mobile-company-side-block .mobile-upgrade-account-banner {
	background: var(--base-bordo);
	padding: 16px 20px;
	margin-top: 20px;
}

.mobile-company-side-block .mobile-upgrade-account-banner .banner-content {
	display: flex;
	justify-content: space-between;
    align-items: center;
}

.mobile-company-side-block .mobile-upgrade-account-banner .banner-content h3,
.mobile-company-side-block .mobile-upgrade-account-banner .banner-content p {
	color: #ffffff;
	margin: 0;
	line-height: 1;
}

.mobile-company-side-block .mobile-upgrade-account-banner .banner-content h3 {
	margin: 0 0 7px;
	font-size: 18px;
}

.mobile-company-side-block .mobile-upgrade-account-banner .ant-btn {
	text-transform: uppercase;
	font-family: manroeMedium;
	font-size: 16px;
	padding: 0 10px;
	width: 180px;
	/* width: 80%; */
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: pulse 2s infinite;
}

.buy-candidates-contact-btn:hover {
    background: #fde384 !important;
}

.buy-candidates-contact-btn .price-match {
    font-family: manroeBold;
	font-size: 16px;
    margin-left: 3px;
}

.view-candidates-info pre {
    font-family: manroeMedium;
    font-size: 16px;
    margin-top: 10px;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}
	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 7px rgba(255, 223, 231, 0.2);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 223, 231, 0.1);
	}
}
