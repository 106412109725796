.custom-stripe-style .job-information {
	margin-bottom: 20px;
}

.custom-stripe-style .payment-candidates-block {
	border-color: #aeaead;
}

.custom-stripe-style .purchase-candidates-btn {
    height: 60px;
	padding: 0 120px;
	margin-top: 40px;
}

.payment-custom-style .return-candidates-btn,
.payment-custom-style .return-candidates-list-btn {
    height: 40px;
	padding: 0 30px;
}

.custom-stripe-style .purchase-candidates-btn,
.payment-custom-style .return-candidates-btn {
	background: var(--base-bordo);
	/* height: 60px;
	padding: 0 120px;
	margin-top: 40px; */
	border: 2px solid var(--base-bordo);
}

.custom-stripe-style .purchase-candidates-btn span,
.payment-custom-style .return-candidates-btn span {
	color: #ffffff;
	font-family: manroeBold;
	font-size: 16px;
}

.custom-stripe-style .purchase-candidates-btn:hover,
.payment-custom-style .return-candidates-btn:hover {
	border: 2px solid var(--base-bordo) !important;
}

.custom-stripe-style .purchase-candidates-btn:hover span,
.payment-custom-style .return-candidates-btn:hover span {
	color: var(--base-bordo);
}

.payment-custom-style .return-candidates-list-btn {
    border: 2px solid var(--base-bordo);
}

.payment-custom-style .return-candidates-list-btn:hover {
    background: var(--base-bordo) !important;
    border-color: var(--base-bordo) !important;
}


.payment-custom-style .return-candidates-list-btn:hover span {
    color: #ffffff !important;
}

.payment-custom-style .return-candidates-list-btn span {
    color: var(--base-bordo);
	font-family: manroeBold;
	font-size: 16px;
}

.custom-stripe-style .ant-card-body{
    padding: 14px 24px;
}

.payment-candidates-block h4 {
    margin-top: 0;
    margin-bottom: 15px;
    font-family: manroeBold;
    font-size: 16px;
    line-height: 1;
}

.payment-candidates-block p {
    margin-top: 0;
    margin-bottom: 7px;
    line-height: 1;
    font-size: 14px;
}

.payment-candidates-block p span {
    font-family: manroeMedium;
    font-size: 14px;
}

.custom-stripe-style .contacts-block .contacts-label {
    margin-right: 20px;
}

.custom-stripe-style .contacts-block p {
    display: flex;
    justify-content: space-between;
}

.custom-stripe-style .contacts-block .mr-3 {
    margin-right: 30px;
}
.total-price {
    color: var(--base-bordo);
    font-size: 20px;
}

.custom-stripe-style .payment-candidates-block .ant-col {
    min-height: 73px;
}

.payment-custom-style .custom-divider {
    display: block;
    height: 1px;
    width: 100%;
    background: #AEAEAE;
}

.payment-custom-style .ant-result {
    border: 3px solid #54AC69;
    border-radius: 14px;
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 25px;
    position: relative;
}

.payment-custom-style .ant-result .ant-result-icon {
    position: absolute;
    top: -38px;
    left: 0;
    right: 0;
}

.payment-custom-style .ant-result .ant-result-icon svg {
    /* fill: #FF4D50; */
    fill: var(--base-bordo);
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid #ffffff;
}

.payment-custom-style .ant-result .ant-result-icon .anticon-check-circle svg {
    fill: #54AC69;
}

.payment-custom-style .ant-result.ant-result-success {
    border-color: #54AC69;
}

.payment-custom-style .ant-result.ant-result-error {
    border-color: var(--base-bordo);
}

.payment-custom-style .ant-result .ant-result-title {
    color: #54AC69;
    font-family: manroeBold;
    font-size: 24px;
}

.payment-custom-style .ant-result.ant-result-success .ant-result-title {
    color: #54AC69;
}

.payment-custom-style .ant-result.ant-result-error .ant-result-title {
    color: var(--base-bordo);
}

.payment-custom-style .ant-result .details-label,
.payment-custom-style .ant-result .details-label span {
    font-family: manroeMedium;
    font-size: 15px;
}

.payment-custom-style .ant-result span.payment-sum {
    font-family: manroeBold;
}