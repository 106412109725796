.subscription-information h2 {
    font-family: manroeBold;
	font-size: 36px;
    margin-top: 0;
    margin-bottom: 15px;
}

.subscription-information p {
    font-family: manroeMedium;
	font-size: 16px;
    /* margin-top: 0; */
}

.subscaption-items-block {
	overflow: scroll;
	touch-action: auto;
	padding: 0 0 30px;
}

.company-subscription-section .subscription-btn {
	border: 2px solid #4eab88;
}

.company-subscription-section .subscription-btn span {
	color: #4eab88 !important;
	font-family: manroeBold;
	font-size: 16px;
	line-height: 1;
}

.company-subscription-section .subscription-btn:hover {
	background: #4eab88 !important;
	border: 2px solid #4eab88 !important;
}

.company-subscription-section .subscription-btn:hover span {
	color: #ffffff !important;
}

.subscaption-card-item .item-header h4 {
	margin-top: 0;
	color: #ecb576;
	font-family: manroeExtraBold;
    font-size: 16px;
	text-transform: uppercase;
	margin-bottom: 0;
}

.subscaption-card-item .item-header p {
	color: #ecb576;
	margin: 0 0 15px;
	font-family: manroeRegular;
    font-size: 16px;
}

.subscaption-card-item .item-header .month-price,
.subscaption-card-item .item-header .month-price span,
.subscaption-card-item .item-header .year-price span,
.subscaption-card-item .item-header .year-price {
	color: #ffffff;
}

.subscaption-card-item .item-header .month-price span,
.subscaption-card-item .item-header .year-price span {
	font-family: manroeBold;
	font-size: 27px;
}

.subscaption-card-item .item-header .month-price,
.subscaption-card-item .item-header .year-price {
	font-family: manroeRegular;
	font-size: 14px;
}

.subscaption-items-block .ant-space {
	align-items: stretch;
}

.subscaption-items-block .ant-card-body {
	height: 100%;
    width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0 0 20px;
}

.subscaption-card-item .item-header .year-price {
    display: block;
    height: 32px;
    line-height: 32px;
    margin-right: 5px;
}

.subscaption-card-item .item-header {
	background: url('../../assets/img/subscription_bg.png') center center no-repeat;
    background-size: cover;
    padding: 12px;
}

.subscaption-items-block .ant-space-item .ant-card {
	width: 210px;
	align-items: center;
    border-color: #aeaeae;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-width: 210px;
    overflow: hidden;
    /* padding: 0 0 20px; */
    transition: all .2s cubic-bezier(.645,.045,.355,1);
}

.subscaption-items-block .ant-space-item {
    width: 100%;
}

.subscaption-items-block .ant-space-item .flicking-viewport{
    padding: 30px 60px 30px 0;
}


.subscaption-items-block .ant-space-item .flicking-camera {
    gap: 16px;
}

.subscaption-card-item .offer-list {
    padding: 0 8px;
    margin: 0;
    border-top: 2px solid #eff0f2;
}

.subscaption-card-item .offer-list .offer-list-item {
    list-style: none;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    border-bottom: 1px solid #b1b1b4;
    padding: 10px 0;
}

.subscaption-card-item .offer-list .offer-list-item:first-child {
    padding-top: 8px;
}

.subscaption-card-item .offer-list .offer-list-item:last-child {
    border-bottom: none;
}

.subscaption-card-item .offer-list .offer-list-item span {
    min-width: 32px;
    display: inline-table;
    font-family: manroeExtraBold;
    font-weight: 900;
	font-size: 14px;
	height: 22px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	text-wrap: nowrap;
	text-align: right;
}

.subscaption-card-item .offer-list .offer-list-item p {
    display: inline-block;
    font-family: manroeMedium;
    font-size: 14px;
    margin: 0;
    /* padding-right: 7px; */
    transition: all .2s cubic-bezier(.645,.045,.355,1);
    white-space: pre-wrap;
}

.subscaption-card-item .offer-list .offer-list-item .anticon {
    font-size: 22px;
    text-align: right;
    height: 22px;
}

.subscaption-card-item .offer-list .offer-list-item .anticon-close-circle svg {
    fill: var(--base-bordo) !important;
    background: #ffffff;
    border-radius: 50%;
}


.subscaption-card-item .offer-list .offer-list-item .anticon-check-circle svg {
    fill: #4eab88 !important;
    background: #ffffff;
	border-radius: 50%;
}

.subscaption-items-block .flicking-camera .ant-card:hover {
    background: #23233b;
}

.subscaption-items-block .flicking-camera .ant-card:hover .offer-list .offer-list-item span,
.subscaption-items-block .flicking-camera .ant-card:hover .offer-list .offer-list-item p {
    color: #ffffff !important;
}

.free-month {
    margin-top: 10px;
}

.custom-badge-free-style,
.custom-badge-style {
    font-family: manroeExtraBold;
    font-size: 11px;
    padding: 4px 7px;
    border-radius: 50rem;
    line-height: 1;
    display: inline-block;
}

.custom-badge-free-style {
    background: #ffc107;
}

.custom-badge-style {
    background: #198754;
    color: #ffffff;
}

.subscaption-card-item .item-header .junker-style {
	display: flex;
	flex-direction: column;
}

.subscaption-card-item .item-header .junker-style span {
	line-height: 1;
	color: #ffffff;
    font-family: manroeMedium;
	font-size: 16px;
}