.recover-login-btn {
    padding: 0;
    transition: all 0.3s;
}

.recover-login-btn:hover span {
    text-decoration: underline;
    font-weight: bold;
}

.reset-btn-signin-style .ant-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-btn-signin-style .ant-btn-icon span svg {
    fill: #ffffff;
}

.reset-btn-signin-style:hover .ant-btn-icon span svg {
    fill: var(--base-bordo);
}

.reset-btn-signin-style span {
    display: flex;
    align-items: center;
    line-height: 1;
}