.custom-sider-style {
	/* padding-top: 75px; */
	border-right: 2px solid #eff0f2;
}

.custom-sider-style .ant-layout-sider-children {
	position: absolute;
	top: 0;
	width: 100%;
	/* padding-top: 65px; */
}

.custom-sider-style .ant-layout-sider-children .custom-sider-content {
	position: sticky;
	height: calc(100vh - 166px);
	top: 122px;
}

.custom-sider-style .custom-sider-footer {
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sider-footer-content a:hover {
	color: var(--base-bordo);
}

.ant-layout-sider-collapsed.custom-sider-style .sider-footer-content .ant-image-img {
	height: 70px;
}

.ant-layout-sider-collapsed.custom-sider-style .sider-footer-content h4 {
	font-size: 14px;
}

.ant-layout-sider-collapsed.custom-sider-style .sider-footer-content a {
	font-size: 14px;
}

.ant-layout-sider-collapsed.custom-sider-style .custom-sider-footer {
	padding-bottom: 10px;
}

.ant-layout-sider-collapsed.custom-sider-style .custom-sider-footer .ant-image {
	margin-right: 0;
}

.sider-footer-text {
	text-align: left;
	padding: 0 5px;
}

.ant-layout-sider-collapsed.custom-sider-style .custom-sider-footer .sider-footer-text {
	text-align: center;
}

.sider-collapsed-btn {
	position: relative;
	left: 0;
	top: 0;
}

.sider-collapsed-btn .anticon {
    font-size: 22px !important;
}

.sider-footer-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.sider-footer-content .ant-image {
	margin-right: 20px;
	transition: all 0.2s;
}

.sider-footer-content .ant-image-img {
	border-radius: 50%;
	height: 80px;
	width: auto;
	transition: all 0.2s;
}

.sider-footer-content h4 {
	margin: 0;
	font-family: manroeBold;
	font-size: 20px;
	transition: all 0.2s;
}

.sider-footer-content a {
	margin-top: 3px !important;
	display: inline-block;
	font-family: manroeMedium;
	font-size: 16px;
	transition: all 0.2s;
}
