:root {
	/* --base-bordo: #7f1535; */
	--base-bordo: #732135;
	--base-graphit: #2a2a35;
	--base-silver: #eff0f2;
	--base-text-color: #2a2a35;
}

@font-face {
	font-family: manroeBold;
	src: url(../font/Manrope-Bold.ttf);
}

@font-face {
	font-family: manroeExtraBold;
	src: url(../font/Manrope-ExtraBold.ttf);
}

@font-face {
	font-family: manroeSemiBold;
	src: url(../font/Manrope-SemiBold.ttf);
}

@font-face {
	font-family: manroeLight;
	src: url(../font/Manrope-Light.ttf);
}

@font-face {
	font-family: manroeExtraLight;
	src: url(../font/Manrope-ExtraLight.ttf);
}

@font-face {
	font-family: manroeMedium;
	src: url(../font//Manrope-Medium.ttf);
}

@font-face {
	font-family: manroeRegular;
	src: url(../font/Manrope-Regular.ttf);
}

html {
	scroll-behavior: smooth;
	/*Adds smooth scrolling instead of snapping to element*/
}

body * {
	font-family: manroeRegular;
	color: var(--base-text-color);
}

.App .container {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.App .row {
	margin: 0 !important;
}

.ant-layout {
	background-color: #ffffff;
}

.App .container,
.App .container-fluid,
.App .container-xs,
.App .container-sm,
.App .container-md,
.App .container-lg,
.App .container-xl,
.App .container-xxl {
	max-width: 1140px;
	margin: 0 auto;
}

.d-flex {
	display: flex;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.d-block {
	display: block;
}

.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}

.position-relative {
	position: relative !important;
}

.custom-padding {
	padding: 0 30px !important;
}
