.company-details-section {
	padding: 0 30px;
	text-align: left;
}

.custom-step-style {
	padding: 30px 20px;
	border: 1px solid #aeaeae;
	border-radius: 12px;
}

.company-details-section .ant-steps-item-title {
	font-family: manroeBold;
	text-wrap: wrap;
}

.company-details-step {
	margin-bottom: 15px;
}

.company-details-step h3 {
	text-transform: uppercase;
	font-family: manroeBold;
	font-size: 16px;
	line-height: 1;
	margin-top: 20px;
	margin-bottom: 20px;
}

.company-details-step h1 {
	font-family: manroeBold;
	font-size: 40px;
	margin-top: 0;
	line-height: 1;
	margin-bottom: 35px;
}

.company-details-step .company-profile-p {
	font-family: manroeMedium;
	font-size: 14px;
	color: #aeaeae;
	margin-bottom: 25px;
	margin-top: 0;
}

.company-details-section .ant-steps-item-icon {
	background: #e0e6e9 !important;
	border-color: #e0e6e9 !important;
	color: #ffffff !important;
}

.company-details-section .ant-steps-icon {
	color: #ffffff !important;
	font-family: manroeBold;
}

.company-details-section .ant-steps-icon svg {
	fill: #ffffff !important;
}

.company-details-section .ant-steps-item-finish .ant-steps-item-icon,
.company-details-section .ant-steps-item-active .ant-steps-item-icon {
	background: var(--base-bordo) !important;
	border-color: var(--base-bordo) !important;
}

.company-details-login-block p {
	font-family: manroeMedium;
}

.company-details-login-block .ant-btn span {
	/* margin-left: 20px; */
	color: var(--base-bordo);
	font-family: manroeBold;
}

.custom-btn-block {
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom-btn-block .ant-btn {
	width: 100%;
	background: var(--base-bordo);
	height: 38px;
	display: flex;
	justify-content: center;
}

.custom-btn-block .ant-btn span {
	color: #ffffff !important;
	font-family: manroeMedium;
	font-size: 16px;
}

.custom-btn-block .ant-btn:hover {
	border-color: var(--base-bordo) !important;
	background: #ffffff;
}

.custom-btn-block .ant-btn:hover span {
	color: var(--base-bordo) !important;
}

.company-details-section .ant-steps-item-finish .ant-steps-item-title:after {
	background-color: var(--base-bordo) !important;
}

.company-details-section .ant-steps-item-process .ant-steps-item-title:after,
.company-details-section .ant-steps-item-finish .ant-steps-item-title:after {
	height: 2px;
}

.company-details-btn-block {
	margin-top: 20px;
}

.company-details-section .ant-form-item {
	margin-bottom: 20px;
}

.company-details-section .ant-form-item label {
	font-family: manroeBold;
	font-size: 16px;
	line-height: 1;
}

.change-passbord-btn {
	margin-top: 20px;
}

.city-zip-style {
	display: flex;
	gap: 12px;
}

.company-details-section .ant-form-item .ant-form-item-control .ant-input-password {
	height: 36px;
}

.company-details-section .ant-form-item .ant-form-item-control .ant-input-password .ant-input {
	height: auto !important;
}

.company-details-section .ant-form-item .ant-form-item-control .ant-select,
.company-details-section .ant-form-item .ant-form-item-control .ant-input {
	height: 36px;
}

.upload-block-style {
	border: 1px solid #aeaeae;
	border-radius: 12px;
	padding: 16px;
}

.compnay-logo-upload {
	margin-bottom: 12px;
}

.upload-block-style .ant-upload-select,
.upload-block-style .ant-upload-list-item-container {
	width: 75px !important;
	height: 75px !important;
}

.upload-block-style .ant-upload-list-item {
	padding: 5px !important;
	border-color: #aeaeae !important;
}

.upload-block-style .ant-upload-list-item .ant-upload-list-item-thumbnail {
	font-size: 12px;
}

.ant-upload-list-item .ant-upload-list-item-thumbnail .ant-upload-list-item-image {
	object-fit: cover !important;
	border: 3px solid #ffffff !important;
	border-radius: 50% !important;
}

.upload-block-style .ant-upload-list-item-progress {
	bottom: 40px !important;
}

.company-name-section {
	padding-top: 30px;
}

.company-name-section .ant-upload-list-item:before {
	width: 88px !important;
	height: 88px !important;
	left: 7px;
}

.company-name-section .ant-upload-list-item:after {
	width: 96px !important;
	height: 96px !important;
	left: 3px;
	content: '';
	background: #ffffff;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
}

.company-name-section .ant-upload-list-item-thumbnail {
	z-index: 9;
}

.company-name-section .ant-upload-list-item-actions svg path {
	color: #ffffff !important;
}

.upload-block-style .ant-upload-list-item:before {
	width: 63px !important;
	height: 63px !important;
}

.upload-block-style .ant-upload-list-item-actions svg path {
	color: #ffffff !important;
}

.upload-block-style h5 {
	margin: 0;
	font-family: manroeBold;
	font-size: 16px;
}

.upload-block-style p {
	font-family: manroeMedium;
	font-size: 12px;
}

.company-details-step .comp-details-head {
	margin-bottom: 65px;
}

.company-activity-list {
	height: 60vh;
	overflow: scroll;
}

.company-activity-list .ant-checkbox-group {
	width: 100%;
}

.company-activity-list .ant-checkbox-group .ant-checkbox-wrapper .ant-checkbox {
	display: none;
}

.company-activity-list .ant-checkbox-group .ant-checkbox-wrapper {
	border: 1px solid #aeaeae;
	border-radius: 12px;
	padding: 16px;
	margin-bottom: 13px;
	width: 100%;
}

.company-activity-list .ant-checkbox-group .ant-checkbox-wrapper:hover {
	border-color: var(--base-bordo);
}

.company-activity-list .ant-checkbox-group .ant-checkbox-wrapper-checked {
	border: 2px solid var(--base-bordo) !important;
	padding: 15px;
}

.company-activity-list .ant-checkbox-group .ant-checkbox-wrapper:last-child {
	margin-bottom: 0;
}

.company-details-step .activity-name {
	font-family: manroeBold;
	font-size: 16px;
}

.company-details-step .step-two-aditional-info .activity-name {
	margin-bottom: 12px;
}

.company-activity-list .ant-checkbox-group .ant-checkbox-wrapper .acivity-descryption {
	font-family: manroeMedium;
	font-size: 12px;
}

.company-details-step .step-two-aditional-info .ant-space {
	margin-bottom: 25px;
}

.company-details-step .search-form-section .ant-input-wrapper {
	position: relative;
	background: #eff0f2;
	border-radius: 12px;
	overflow: hidden;
}

.ant-select-item.ant-select-item-option-selected > .ant-select-item-option-content {
	color: #ffffff !important;
}

.company-details-step .search-form-section .ant-input-outlined:focus {
	box-shadow: 0 0 0 2px rgb(115 34 52 / 10%);
}

.company-details-step .search-form-section .ant-input-prefix svg {
	width: 20px;
	height: 20px;
}

.company-details-step .search-form-section .ant-input-affix-wrapper {
	padding: 0 10px;
	background: #eff0f2;
	overflow: hidden;
	border-radius: 12px;
	margin-bottom: 15px;
}

.company-details-step .search-form-section .ant-input {
	height: 60px;
	background: #eff0f2;
	/* border-radius: 0 12px 12px 0; */
	border-left: none;
	color: #2a2a35;
	padding-left: 5px;
}

.company-details-step .search-form-section .ant-input:focus {
	background: #ffffff;
}

.company-details-step .search-form-section .ant-input-affix-wrapper:has(.ant-input:focus) {
	background: #ffffff !important;
}

.company-details-step .search-form-section .ant-input::placeholder {
	color: #2a2a35;
	font-family: manroeMedium;
	font-size: 14px;
}

.company-details-step .step-two-aditional-info .ant-input-number-input,
.company-details-step .step-two-aditional-info .ant-select,
.company-details-step .step-two-aditional-info .ant-picker {
	height: 35px;
}

.open-company-settings-btn {
	background: #f8fafc;
	border-radius: 8px;
	height: 42px;
	width: 42px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

.open-company-settings-btn svg {
	width: 34px;
	height: 34px;
	transform: rotate(90deg);
}

.company-details-step-three {
	border: 1px solid #aeaeae;
	border-radius: 12px;
	overflow: hidden;
	padding: 0;
}

.company-details-step-three .ant-layout-sider {
	padding: 90px 0 0;
	border-right: 1px solid #aeaeae;
}

.company-name-section .ant-upload-wrapper .ant-upload-list {
	text-align: center;
}

.company-name-section .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
	margin: 0 !important;
}

.company-name-section .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container .ant-upload-list-item {
	border: none;
	padding: 4px;
	/* border-width: 4px;
	border-style: solid; */
	/* border-image: linear-gradient(left top, crimson 0%, #f90 100%); */
	background-image: conic-gradient(rgba(115, 33, 54, 1), rgba(115, 33, 54, 0.8), rgba(115, 33, 54, 0));
}

.company-profile-menu .ant-menu-item {
	margin-bottom: 30px;
	height: 48px;
	border-right: 2px solid transparent;
	display: flex;
	align-items: center;
	
}

.company-profile-menu .ant-menu-item svg {
	width: 20px;
	height: 20px;
	min-width: 20px !important;
}

.custom-sider-menu  {
	border-inline-end: none !important;
}

.custom-sider-menu .ant-menu-item {
	padding-left: 25% !important;
}

.custom-sider-menu .ant-menu-title-content {
	margin-left: 20px !important;
}

.company-profile-menu .ant-menu-item .ant-menu-title-content {
	margin-left: 25px;
	font-family: manroeMedium;
	font-size: 16px;
	text-align: left;
}

.company-profile-menu .ant-menu-item:hover {
	border-right: 2px solid var(--base-bordo);
	background: #f2e7ea !important;
}

.company-profile-menu .ant-menu-item:hover .ant-menu-title-content,
.company-profile-menu .ant-menu-item:hover .ant-menu-title-content a,
.company-profile-menu .ant-menu-item.ant-menu-item-selected .ant-menu-title-content a,
.company-profile-menu .ant-menu-item.ant-menu-item-selected .ant-menu-title-content {
	color: var(--base-bordo);
	font-family: manroeBold !important;
}

.company-profile-menu .ant-menu-item svg .st0,
.company-profile-menu .ant-menu-item svg .st1 {
	fill: #6c6c6b;
}

.company-profile-menu .ant-menu-item:hover svg .st0,
.company-profile-menu .ant-menu-item:hover svg .st1,
.company-profile-menu .ant-menu-item.ant-menu-item-selected svg .st0,
.company-profile-menu .ant-menu-item.ant-menu-item-selected svg .st1 {
	fill: var(--base-bordo);
}

.company-profile-menu.ant-menu-inline-collapsed .ant-menu-item {
	padding-inline: calc(50% - 10px - 0px) !important;
}

.company-name-section .upgrade-account-banner {
	position: relative;
}

.company-name-section .upgrade-account-banner .ant-image {
	width: 100%;
}

.company-name-section .upgrade-account-banner .banner-content {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	padding: 25px 15px;
}

.company-name-section .upgrade-account-banner .banner-content h3,
.company-name-section .upgrade-account-banner .banner-content p {
	color: #ffffff;
	text-wrap: balance;
}

.company-name-section .upgrade-account-banner .banner-content h3 {
	font-family: manroeExtraBold;
	font-size: 21px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.company-name-section .upgrade-account-banner .banner-content p {
	font-family: manroeMedium;
	font-size: 16px;
	margin-bottom: 20px;
}

.company-name-section .upgrade-account-banner .ant-btn {
	text-transform: uppercase;
	font-family: manroeMedium;
	font-size: 16px;
	padding: 0 10px;
	/* width: 180px; */
	width: 80%;
	position: relative;
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: center;
	/* box-shadow: 0 0 0 0 rgba(115, 33, 54, 0.1); */
	animation: pulse 2s infinite;
}

.company-name-section .upgrade-account-banner .ant-btn:hover {
	border-color: var(--base-bordo);
	animation: none;
	transform: scale(1.1);
}

.company-name-section .upgrade-account-banner .ant-btn:hover span {
	color: var(--base-bordo) !important;
	font-weight: 900;
}

.company-name-section .upgrade-account-banner .ant-btn:hover span svg {
	fill: var(--base-bordo);
	/* transform: scale(1.1); */
}

.company-name-section .upgrade-account-banner .ant-btn span.anticon {
	position: absolute;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}
	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 7px rgba(255, 223, 231, 0.2);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 223, 231, 0.1);
	}
}

.company-info-block .company-name {
	font-family: manroeMedium;
	font-size: 24px;
	margin-bottom: 12px;
	margin-top: 25px;
	line-height: 1;
	padding: 0 7px;
}

.company-info-block .company-email {
	font-family: manroeMedium;
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 25px;
	line-height: 1;
	padding: 0 7px;
}