.job-table .job-position-info p {
	display: flex;
	align-items: center;
}

.job-table .job-position-info p svg {
	width: 20px;
	height: 20px;
	position: relative;
	top: -2px;
}

.job-table .job-position-info p svg .st0 {
	fill: #d6dbdd;
}

.job-table .job-position-info p .job-offers-name {
	line-height: 1;
	margin-left: 10px;
	font-family: manroeMedium;
	font-size: 16px;
	text-wrap: balance;
}

.job-table table tr td {
	border: none !important;
	padding: 7px 16px !important;
}

.job-table table tr:nth-of-type(2n + 1) {
	background: #f7f8f8;
}

.job-table table .ant-table-thead tr,
.job-table table .ant-table-thead tr th {
	background: none !important;
}

.job-table table .ant-table-thead tr th {
	border: none;
}

.job-table table .ant-table-thead tr th:before {
	display: none;
}

.job-table .job-status .ant-tag {
	font-family: manroeMedium;
	font-size: 16px;
	padding: 3px 10px;
	line-height: 1;
}

.job-table .job-status .ant-tag-green {
	color: #6af2bf;
	background: #e3f7f0;
	border-color: #6af2bf;
}

.job-table .job-status .ant-tag-orange {
	color: #f6c344;
	background: #f7f0dd;
	border-color: #f6c344;
}

.job-action-list-btn {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.job-action-list-btn button {
	width: 42px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.job-action-dropdown-overlay {
	inset: -2px -2px auto auto !important;
}

.job-action-dropdown-overlay .ant-popover-inner {
	padding: 0px;
}

.job-action-list-btn .job-action-list-item {
	cursor: pointer;
	height: 34px;
	width: 42px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 3px;
	border-radius: 8px;
	outline: none;
	position: relative;
	white-space: nowrap;
	border: 1px solid transparent;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	user-select: none;
	touch-action: manipulation;
	overflow: hidden;
}

.job-action-list-btn .job-action-list-item:hover {
	background: rgba(0, 0, 0, 0.06);
}

.job-action-list-btn .job-action-list-item:active {
	background: rgba(0, 0, 0, 0.15);
}

.job-action-list-btn .job-action-list-item:first-child {
	margin-left: 0;
}

.job-action-list-btn .job-action-list-item:last-child {
	margin-right: 0;
}

.job-table .job-action .ant-btn.ant-btn-icon-only .anticon {
	font-size: 23px !important;
}

.job-table .job-action .ant-btn.ant-btn-icon-only .anticon svg {
    fill: #5a6467;
}

.job-table table .ant-table-thead tr th {
    font-family: manroeBold;
	font-size: 16px;
}

.job-information {
    border-bottom: 1px solid #aeaeae;
    padding-bottom: 26px;
}

.job-information h2 {
    font-family: manroeBold;
	font-size: 36px;
}

.job-information p {
    font-family: manroeMedium;
	font-size: 16px;
}

.job-table .ant-pagination-item {
    border-color: var(--base-bordo) !important;
}

.job-table .ant-pagination-item a {
    color: var(--base-text-color);
}

.job-table .ant-pagination-item a:hover {
    color: var(--base-bordo);
}

.job-table .ant-pagination .ant-pagination-item-link {
    padding: 0 6px;
    border-radius: 6px;
    border: 1px solid #aeaeae;
}

.job-table .ant-pagination .ant-pagination-item-link:hover {
    border-color: var(--base-bordo) !important;
}

.job-table .ant-pagination {
    margin-top: 60px !important;
}

.job-offers-search-block {
    margin: 25px 0;
}

.job-offers-search-block .search-form-section .ant-input-prefix svg {
    width: 17px;
    margin-right: 5px;
}

.job-table table .ant-table-row {
	cursor: pointer;
}

.ant-message .error-delete-job-icon .anticon svg {
	fill: #ff4d4f;
	font-size: 18px;
}


.ant-message .succes-delete-job-icon .anticon svg {
	fill: #52c41a;
	font-size: 18px;
}

.ant-message .succes-delete-job-icon .ant-message-custom-content,
.ant-message .error-delete-job-icon .ant-message-custom-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ant-message .succes-delete-job-icon span,
.ant-message .error-delete-job-icon span {
	font-family: manroeBold;
	font-size: 16px;
}

.ant-message {
	top: 75px !important;
}

.ant-popconfirm .ant-popconfirm-message-icon .anticon-exclamation-circle svg {
	fill: #faad14;
	font-size: 18px;
}

.ant-popconfirm .ant-popconfirm-buttons .ant-btn-primary {
	background: var(--base-bordo);
}

.ant-popconfirm .ant-popconfirm-buttons button:hover {
	border-color: var(--base-bordo) !important;
}

.ant-popconfirm .ant-popconfirm-buttons button:hover span {
	color: var(--base-bordo);
}

.ant-popconfirm .ant-popconfirm-buttons button span {
	font-family: manroeBold;
	font-size: 14px;
	line-height: 1;
}

.ant-popconfirm .ant-popconfirm-buttons .ant-btn-primary span {
	color: #ffffff;
}

.ant-popconfirm .ant-popconfirm-buttons .ant-btn-primary:hover {
	background: none;
}

.job-information h2,
.job-offer-details .edit-block h2 {
	font-size: 32px;
}

.job-offer-details .edit-block {
	margin-bottom: 5px;
}

.view-office-pictures .image-list {
	display: flex;
	gap: 12px;
}

.view-office-pictures .ant-image-mask .ant-image-mask-info {
	color: #ffffff;
	font-family: manroeBold;
	font-size: 13px;
}

.view-office-pictures .ant-image-mask .ant-image-mask-info span svg {
	fill: #ffffff;
}

.ant-image-preview-operations-wrapper span svg {
	fill: #ffffff;
}

.job-details-row {
	display: flex;
    align-items: stretch;
	margin-bottom: 30px;
}

.job-details-row .ant-col {
	text-align: left;
	display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.job-details-row .custom-label {
	font-family: manroeBold !important;
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 10px;
	height: 22px;
}

.subscription-true-block pre,
.subscription-true-block span.currency-format,
.subscription-true-block .job-details-row p {
	font-family: manroeMedium;
	font-size: 16px;
	margin: 0 0 7px;
	text-wrap: balance;
}

.subscription-true-block small {
	font-size: 14px;
}

.subscription-true-block .job-details-content {
	margin-bottom: 25px;
}

.office-picture-job-offer .ant-modal-header {
	height: 24px;
}

.office-picture-job-offer .ant-modal-title {
	display: none;
}

.publish-job-section .cancel-job-btn span {
	color: var(--base-bordo) !important;
}

.publish-job-section .publish-job-btn.cancel-job-btn:hover {
	background: var(--base-bordo) !important;
}

.publish-job-section .publish-job-btn.cancel-job-btn:hover span {
	color: #ffffff !important;
}

.publish-job-section .publish-job-btn:hover {
	background: #ffffff !important;
	border: 2px solid var(--base-bordo);
}

.publish-job-section .publish-job-btn:hover span {
	color: var(--base-bordo) !important;
}

.publish-job-section .publish-job-btn:hover>span>svg .st0 {
	fill: var(--base-bordo);
}

.job-action-canditates-list {
	display: inline-block;
	padding: 1px 7px;
	border-radius: 4px;
}

.job-action-canditates-list:hover {
	background: rgba(0, 0, 0, 0.06);
}

.job-action-canditates-list span {
	text-decoration: underline;
	color: var(--base-bordo);
	font-family: manroeSemiBold;
	font-size: 15px;
	position: relative;
	top: -1px;
}