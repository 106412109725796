.publish-job-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.publish-job-btn span {
	color: #ffffff !important;
	font-family: manroeMedium;
	font-size: 16px;
	line-height: 1;
}

.publish-job-btn .ant-btn-icon {
	position: absolute;
	left: 16px;
}

.publish-job-btn .ant-btn-icon svg {
	width: auto;
	height: 20px;
}

.publish-job-btn.add-job-comp:hover span {
	color: #51ad65 !important;
}

.publish-job-btn.add-job-comp:hover svg .st0 {
	fill: #51ad65 !important;
}

#companyDetailsForm .ant-form-item .ant-form-item-control .ant-select,
#companyDetailsForm .ant-form-item .ant-form-item-control input.ant-input {
	height: 36px;
}

.city-zip-style label[for='companyDetailsForm_maxSalary']:after,
.city-zip-style label[for='companyDetailsForm_languages_button']:after {
	display: none;
}

.city-zip-style .ant-form-item-label:has(label[for='companyDetailsForm_maxSalary']),
.city-zip-style .ant-form-item-label:has(label[for='companyDetailsForm_languages_button']) {
	height: 32px !important;
}

.job-information h2,
#companyDetailsForm .edit-block h2 {
	font-size: 32px;
}

#companyDetailsForm .ant-form-item-label label {
	font-family: manroeBold;
	font-size: 16px;
}

#companyDetailsForm .max-symbol-label .ant-form-item-label label {
	font-family: manroeRegular;
	font-size: 14px;
}

.picture-wall-upload .ant-upload-list-item-actions svg path {
	color: #ffffff !important;
}

#companyDetailsForm .ant-select-selection-item {
	text-align: left;
}

#companyDetailsForm .ant-select-selector {
	text-align: left;
}

#companyDetailsForm .lang-btn-style .ant-form-item-label {
	height: 32px;
}

#companyDetailsForm .lang-btn-style .ant-form-item-label label:after {
	display: none;
}

#companyDetailsForm .lang-btn-style .ant-form-item-control-input {
	min-height: 36px;
}

.custom-multiple-type-select .ant-select-item {
	margin-bottom: 5px;
}

.custom-multiple-type-select .ant-select-item:last-child {
	margin-bottom: 0;
}

.custom-multiple-type-select .ant-select-item .anticon svg {
	fill: #ffffff;
}

.custom-multiple-type-select .ant-select-item.ant-select-item-option-selected span,
.custom-multiple-type-select .ant-select-item.ant-select-item-option-selected small {
	color: #ffffff;
}

.custom-multiple-type-select span {
	font-family: manroeBold;
	font-size: 16px;
}

.subscription-false-block {
	position: absolute;
	top: -10px;
	left: -10px;
	right: -10px;
	bottom: -10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.26);
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(8px);
	-webkit-backdrop-filter: blur(8px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}

.subscription-false-block h3 {
    font-family: manroeBold;
	font-size: 32px;
    color: var(--base-bordo);
}

.limit-subscribtion-message {
    border: 2px solid var(--base-bordo);
    border-radius: 16px;
    padding: 20px 16px 20px 30px;
    background: #F2E7EA;
}

.limit-subscribtion-message h3 {
    margin: 0;
    font-family: manroeBold;
	font-size: 19px;
    line-height: 1;
}

.limit-subscribtion-message small {
    margin: 0;
    font-family: manroeMedium;
	font-size: 14px;
    color: var(--base-bordo);
}

.limit-subscribtion-message .anticon svg {
    width: 72px;
    height: 72px;
    margin-left: 25px;
    fill: var(--base-bordo);
}

.open-message-style .ant-message-success {
    display: flex;
    align-items: center;
}

.open-message-style .ant-message-success span {
    font-family: manroeBold;
    font-size: 14px;
}

.open-message-style .ant-message-error svg {
    fill: #FF4D50;
}

.open-message-style .ant-message-success svg {
    fill: #52c41a;
}

