.custom-loading-style {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ebebeb;
    z-index: 99;
    height: 100vh;
}