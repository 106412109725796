.login-modal-block .ant-modal-content {
    padding: 0 0 70px;
    overflow: hidden;
    border-radius: 18px;
}

.login-modal-block .login-block {
    padding: 60px 115px 0;
}

.login-modal-block .login-block  h2 {
    margin-top: 0;
}

.login-modal-block .hello-block h3 {
    margin-top: 0;
    font-family: manroeBold;
    font-size: 27px;
    line-height: 1;
}

.login-modal-block .hello-block p {
    font-family: manroeMedium;
    font-size: 14px;
    text-wrap: balance;
}

.login-modal-block #loginForm .ant-form-item-control-input .ant-input {
    border-radius: 4px;
    height: 36px;
}

.login-modal-block #loginForm #loginForm_password {
    height: 34px;
}

.login-modal-block #loginForm .ant-form-item-control-input .ant-input-password{
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 4px;
    height: 36px;
}

.login-modal-block .ant-modal-close-icon {
    font-size: 24px;
}

.login-modal-block .ant-modal-close-icon svg path {
    fill: var(--base-bordo);
    stroke: var(--base-bordo);;
    stroke-width: 5%;
}

.login-modal-block .login-block h2 {
    font-family: manroeBold;
    font-size: 42px;
    margin-top: 0;
    margin-bottom: 25px;
    line-height: 1;
}

.login-modal-block .login-block p {
    font-family: manroeMedium;
    font-size: 14px;
    color: #abb7bb;
    margin-top: 0;
    margin-bottom: 25px;
}

.login-modal-block .login-block label {
    font-family: manroeBold;
    font-size: 14px;
}

.login-modal-block .login-block label span {
    font-family: manroeMedium;
    font-size: 14px;
}

.login-modal-block .sign-in-style,
.login-modal-block .password-style {
    margin-bottom: 10px;
}

.login-modal-block .login-copyright-block a,
.login-modal-block .create-account-link,
.login-modal-block .forgot-password {
    font-family: manroeMedium;
    font-size: 14px;
}

.login-modal-block .create-account-link,
.login-modal-block .forgot-password {
    text-decoration: underline;
}

.login-modal-block .login-copyright-block a:hover,
.login-modal-block .create-account-link:hover,
.login-modal-block .forgot-password:hover {
    color: var(--base-bordo);
    font-weight: bold;
    transition: all 0.3s;
}

.login-modal-block .login-block .have-account {
    margin-bottom: 0;
    font-family: manroeMedium;
    font-size: 14px;
    color: var(--base-text-color);
}

.login-modal-block .login-copyright-block {
    width: fit-content;
    margin: 40px auto 0;
}

.login-modal-block .login-copyright-block p {
    margin-bottom: 5px;
}

.open-message-style .ant-message-error {
    display: flex;
    align-items: center;
}

.open-message-style .ant-message-error span {
    font-family: manroeBold;
    font-size: 14px;
}

.open-message-style .ant-message-error svg {
    fill: #FF4D50;
}