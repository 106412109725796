.footer {
    background: #2a2a35;
    height: 120px;
    border-top: 1px solid #74747c;
}

.App .container.footer-container {
    height: 120px;
    padding: 0 30px !important;
}

.footer-menu ul {
    display: flex;
    padding-left: 0;
}

.footer-menu ul li {
    list-style: none;
    margin-right: 20px;
}

.footer-menu ul li a,
.copyright-text {
    color: #ffffff !important;
    font-size: 16px;
}

.bottom-images-style {
	margin-top: 255px;
    display: flex;
    justify-content: center;
}

.ant-layout-footer {
    background: inherit;
    padding: 0;
}