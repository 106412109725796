.company-information-block {
    padding: 0 0 30px 40px;
}

.edit-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.edit-block h2 {
    font-family: manroeBold;
	font-size: 36px;
    margin: 0;
    text-align: left;
}

.company-information-block p {
    font-family: manroeMedium;
	font-size: 16px;
    margin-top: 10px;
}

.company-information-block .information,
.company-information-block .about,
.company-information-block .contact,
.company-information-block .activities {
    padding: 15px 48px 15px 0;
}

.company-information-block .information,
.company-information-block .about,
.company-information-block .contact {
    border-bottom: 1px solid #aeaeae;
}

.company-information-block .information {
    padding-top: 0;
}

.company-information-block .ant-btn {
    line-height: 1;
    transition: all .1s;
}

.company-information-block .ant-btn span {
    color: var(--base-bordo) !important;
    font-family: manroeBold;
	font-size: 16px;
    line-height: 1;
    transition: all .1s;
}

.company-information-block .ant-btn:hover {
    background: var(--base-bordo) !important;
}

.company-information-block .ant-btn:hover span {
    color: #ffffff !important;
}

.activities-content h5 {
    font-family: manroeBold;
	font-size: 16px;
    margin: 0;
}

.activities-content p {
    font-family: manroeMedium;
	font-size: 16px;
    margin: 5px 0;
}

.contact .contact-data-label {
    min-width: 100px;
    font-family: manroeMedium;
	font-size: 16px;
}

.contact .contact-data-value {
    font-family: manroeBold;
	font-size: 16px;
    padding-left: 10px;
}

.edit-info-company {
    margin-bottom: 20px;
}

.company-activity-list .activity-name {
    font-family: manroeBold;
    font-size: 16px;
}

.edit-company-modal .ant-modal-content .ant-btn-primary {
    background: var(--base-bordo);
}

.edit-company-modal .ant-modal-content .ant-btn-default span {
    color: var(--base-bordo);
    font-family: manroeBold;
}

.edit-company-modal .ant-modal-content .ant-btn-primary span {
    color: #ffffff;
    font-family: manroeBold;
}

.edit-company-modal .ant-modal-content .ant-btn-default:hover {
    background: var(--base-bordo);
}

.edit-company-modal .ant-modal-content .ant-btn-default:hover span {
    color: #ffffff;
}

.edit-company-modal .ant-modal-content .ant-btn-primary:hover {
    background: #ffffff;
    border: 2px solid var(--base-bordo);
}

.edit-company-modal .ant-modal-content .ant-btn-primary:hover span {
    color: var(--base-bordo);
}

.edit-company-modal .ant-modal-content .anticon-loading svg {
    fill: #ffffff;
}

.edit-company-modal .ant-modal-content .aboutCommpnay-style .ant-col {
    min-width: 100% !important;
}

.mobile-company-side-block {
    display: none;
    margin-bottom: 30px;
    border-color: #aeaeae;
}

.mobile-company-sider-btn {
    font-size: 16px;
    height: 47px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-pass-block {
    padding-right: 40px;
}