.change-language-block {
	order: 2;
    display: inline-block;
}

header {
	height: 100% !important;
	position: sticky;
	top: 0;
	z-index: 999;
	background: #ffffff !important;
	-webkit-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	-moz-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
}

.header-block {
	height: 75px !important;
	padding: 0 !important;
}

.header-block h4 {
	font-family: manroeBold;
    font-size: 18px;
	line-height: 1;
	margin: 0;
}

.header-block .container {
	position: relative;
	height: 100%;
}

.header-block .navbar-brand {
	position: relative;
	left: 0;
	top: 0;
	z-index: 99;
	padding: 0;
	margin: 0;
	display: inline-block;
	height: 122px;
    width: 122px;
}

.header-nav-menu {
	position: absolute;
	display: flex;
	align-items: flex-end;
	top: 0;
	left: 0;
	border-bottom-right-radius: 14px;
}

.header-nav-menu:after {
	content: '';
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 0;
    background: #ffffff;
    border-bottom-right-radius: 14px;
    -webkit-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	-moz-box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
	box-shadow: 0px 1px 15px -3px rgba(42, 42, 53, 0.3);
}

.change-language-block .ant-radio {
	width: 37px;
    height: 37px;
	margin: 0;
	position: absolute;
	border: none;
	background: none;
    left: -2px;
    top: -7px;
	z-index: -1;
}

.change-language-block .ant-radio .ant-radio-inner {
    display: none;
}

.change-language-block .ant-form-item {
    margin: 0;
}

.ant-radio-checked {
	background: var(--base-bordo) !important;
	background-image: none !important;
	border: none;
	box-shadow: none;
}

.change-language-block form {
	height: 30px;
	border: 1px solid var(--base-bordo);
	border-radius: 20px;
	display: flex;
    justify-content: space-around;
    align-items: center;
	isolation: isolate;
    background: #ffffff;
}

.change-language-block .ant-radio-wrapper {
	position: relative;
	width: 34px;
	text-align: center;
    margin: 0;
}

.change-language-block .ant-radio-input {
    margin: 0;
}

.change-language-block .form-check-input:focus {
	border: none;
	box-shadow: none;
}

.change-language-block .ant-radio-group label span:not([class]) {
	color: var(--base-bordo) !important;
	font-weight: 600;
	text-transform: uppercase;
	z-index: 99;
	font-size: 14px;
	height: 100%;
	cursor: pointer;
}

.change-language-block .ant-radio-group .ant-radio-wrapper-checked span:not([class]) {
	color: #ffffff !important;
}

.header-menu-position {
	position: absolute;
	right: 30px;
}
